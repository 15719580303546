import React from 'react';
import { Roles, LockType } from '../utils/enums';
// import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { formatPhoneNumber as formatPhoneNumberUs, formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input'

import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export const formatPhoneNumber = d => {
  if (d) {
    return d.startsWith('+1') ? formatPhoneNumberUs(d) : formatPhoneNumberIntl(d)
  }
  return d
}

export const formatRoleFromEnum = d => {
  let roleName = ''
  switch (d) {
    case Roles.USER: {
      roleName = 'User'
      break;
    }
    case Roles.ADMIN: {
      roleName = 'Administrator'
      break;
    }
    case Roles.OWNER: {
      roleName = 'Owner'
      break;
    }
    default: {
      break;
    }
  }
  return roleName === '' ? '' : formatRole(roleName)
}

export const formatRole = d => {
  // const icon = (d && d.indexOf("Group") !== -1) ? <GroupWorkIcon color="secondary" style={{fontSize: 10, position: "absolute", right: 0, verticalAlign: "middle", opacity: .3}} /> : ''
  const classes = 'role role-' + d
  d = abbreviateRole(d)
  return (<span><span className={classes} style={{position: "relative"}}>{d}</span></span>)
}

export const abbreviateRole = (d,isExport) => {
  if (d === 'Administrator') {
    return 'Admin'
  }
  else if (d === 'GroupAdmin') {
    return isExport ? 'Group Admin' : 'Admin'
  }
  else if (d === 'GroupOwner') {
    return isExport ? 'Group Owner' : 'Owner'
  }
  else if (d === 'GroupUser') {
    return isExport ? 'Group User' : 'User'
  }
  return d
}

export const sortByName = (entities) => {
  entities.sort(function(userOne,userTwo) {
    // Sort by full name, then phone, then email
    const userOneFullName = formatFullName(userOne) || "zzzzzzzzzzzzzzzzzz" // sort to the bottom using an arbitrarily late-in-the-alphabet string
    const userTwoFullName = formatFullName(userTwo) || "zzzzzzzzzzzzzzzzzz" //

    return userOneFullName.localeCompare(userTwoFullName) 
      || getPropertyValue(userOne, "primaryEmail", "").localeCompare(getPropertyValue(userTwo, "primaryEmail", ""))
      || getPropertyValue(userOne, "primaryPhone", "").localeCompare(getPropertyValue(userTwo, "primaryPhone", ""));
  });
  return entities
}

export const formatName = d => {
  let userIdentifier = formatFullName(d)
  if (userIdentifier.trim() === '') {
    userIdentifier = getPropertyValue(d, "primaryPhone") || ''
  }
  if (userIdentifier.trim() === '') {
    userIdentifier = getPropertyValue(d, "primaryEmail") || ''
  }
  return userIdentifier
}

export const formatLockName = d => {
  return d ? getPropertyValue(d, "name", "") : ""
}

export const formatFullName = d => {
  return (getPropertyValue(d, "firstName") || '') + ' ' + (getPropertyValue(d, "lastName") || '').trim()
}

export const formatArrayToSingleLine = arr => {
  // NexCore 4125, Nexkey 47_60, and Nexkey 4633
  return arr ? [arr.slice(0, -1).join(', '), arr.slice(-1)[0]]
    .join(arr.length < 2 ? '' : ' and ') : ''
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatDate = (date, excludeTimeZone) => {
  const options = { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' }
  if (excludeTimeZone) {
    delete options.timeZoneName
  } 
  return new Date(date).toLocaleDateString(undefined, options)
}

export const formatTime = (date) => {
  return new Date(date).toLocaleTimeString(undefined, { timeStyle: "short" })
}

export const getTimeStampFromProperty = (parent, property) => {
  const timeStampRaw = getPropertyValue(parent, property)
  if (timeStampRaw && timeStampRaw.hasOwnProperty("iso")) {
    return timeStampRaw.iso
  }
  return timeStampRaw || null
}

export const getPropertyValue = (parent, property, expectedData) => {
  return property
    .split('.') // split string based on `.`
    .reduce(function(o, k) {
      return o && o[k]; // get inner property if `o` is defined else get `o` and return
    }, parent) || expectedData // set initial value as object
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getErrorFromResponse = (error) => {
  const defaultError = "Error retrieving data."
  const errorMessage = getPropertyValue(error, "data")
  if (errorMessage && isJsonString(errorMessage)) {
    const errorMessageObj = JSON.parse(errorMessage)
    return getPropertyValue(errorMessageObj, "error", defaultError)
  }
  return defaultError
}

export const formatPhoneNumberToE164 = d => {
  let phoneObject
  try {
    phoneObject = parsePhoneNumber(String(d).trim(), "US")
    if (phoneObject && !phoneObject.isValid()) {
      console.log("formatPhoneNumberToE164", `invalid phone ${d}`)
      return ""
    }
  } catch(err) {
    console.log("formatPhoneNumberToE164", `${err} on formatting phone ${d}`)
  }
  return (phoneObject && phoneObject.number) || ""
}

export const trimUserIdentifier = (userIdentifier) => {
  userIdentifier = userIdentifier.trim()
  if (userIdentifier.indexOf('@') === -1) {
    userIdentifier = formatPhoneNumberToE164(userIdentifier)
  }
  return userIdentifier
}

/**
 * Note: only works with moment object and the params must be mutable
 */
 export const isNowInRangeWithTimezoneV2 = function(startDate, endDate, timezone){
  const now = moment.tz(timezone)

  const datesSpanDayRetryAttempt = ( endDate.dayOfYear() - startDate.dayOfYear() !== 0 )
  const convertedRange = convertDateRangeToToday(startDate, endDate, timezone, datesSpanDayRetryAttempt)
  const startDateN = convertedRange.startRange
  const endDateN = convertedRange.endRange

  const nowUtc = now.clone().utc()
  const startDateNowUtc = startDateN.clone().utc()
  const endDateNowUtc = endDateN.clone().utc()
  let rangeNowUtc = moment.range(startDateNowUtc, endDateNowUtc);
  // console.log(`DEBUGGING: now ${now} in startRange ${startDateN} endRange ${endDateN}}`)
  // console.log(`DEBUGGING: nowUtc ${nowUtc} in startRangeUtc ${startDateNowUtc} endRangeUtc ${endDateNowUtc}}`)
  if (rangeNowUtc.contains(nowUtc)) {
    return {'startRange': startDateN, 'endRange': endDateN}
  } 

  return null
}

export const businessHoursValidNow = (lock) => {
  const lockTimezone = getPropertyValue(lock, "timeZone")
  const currentDate = moment.tz(lockTimezone)
  const businessHours = getPropertyValue(lock, "businessHours", [])
  for (let businessHour of businessHours) {
    if (getPropertyValue(businessHour, "disabled", false) === false) {
      const sameDay = (getPropertyValue(businessHour, "repeatPattern", []).includes(currentDate.day()))
      const nowInRange = sameDay ? isNowInRangeWithTimezoneV2(
        moment(getTimeStampFromProperty(businessHour, "startDate")).tz(lockTimezone), 
        moment(getTimeStampFromProperty(businessHour, "endDate")).tz(lockTimezone), 
        lockTimezone
      ) : null
      if (nowInRange) {
        return {
          startTime: formatTime(nowInRange.startRange),
          endTime: formatTime(nowInRange.endRange),
        }
      }
    }
  }
  return null
}

export const isLockLastBatteryChangeEligible = (key) => {
  return key.lockType === LockType.CYLINDER 
    || key.lockType === LockType.CYLINDER_17 
    || key.lockType === LockType.CYLINDER_V1_2
    || key.lockType === LockType.CYLINDER_ILCO_V1_2
    || key.lockType === LockType.SOLO
    || key.lockType === LockType.SOLO_V2
    || key.lockType === LockType.SOLO_17
    || key.lockType === LockType.SOLO_V1_2
}

export const getMarketingNameForLockType = (lockType) => {
  switch (lockType) {
    case LockType.CYLINDER:
    case LockType.CYLINDER_17:
    case LockType.CYLINDER_V1_2:
    case LockType.CYLINDER_ILCO_V1_2:
      return "Core"
    case LockType.ELATCH:
    case LockType.ELATCH_17:
    case LockType.ELATCH_V1_2:
      return "Controller"
    case LockType.VIRTUAL:
      return "Virtual"
    case LockType.SOLO:
    case LockType.SOLO_V2:
    case LockType.SOLO_17:
    case LockType.SOLO_V1_2:
      return "Solo"
    default:
      return lockType
  }
}

const convertDateRangeToToday = function (startDate, endDate, timezone, datesSpanDayRetryAttempt, utcTimeTomorrowButReallyTodayAfterUtcDayRoll) {
  let startRange = convertToTodaysDate(startDate, timezone)
  let endRange = convertToTodaysDate(endDate, timezone)

    // handle the cases where the set range spans a calendar day
    if(startDate instanceof moment) {
      if(startDate.date() < endDate.date() || startDate.month() < endDate.month()) {
        endRange.add(1, 'days')
      }

      // have to consider the case that some one set a time 16:00-10:00 and it is 09:00 that following day.
      // if just do what is below then will not be in a valid range due to it not being 16:00 yet "today", thus the datesSpanDayRetryAttempt is true
      if (datesSpanDayRetryAttempt) {
        startRange.add(-1, 'days');
        endRange.add(-1, 'days');
      } else if (utcTimeTomorrowButReallyTodayAfterUtcDayRoll) {
        startRange.add(1, 'days');
        endRange.add(1, 'days');
      }
    } 
    else if (startDate.getDate() < endDate.getDate() ||
      startDate.getMonth() < endDate.getMonth()){
      // add one calendar day
      endRange = new Date(endRange.setTime( endRange.getTime() + 86400000)) //86400000 ms in a day

      // have to consider the case that some one set a time 16:00-10:00 and it is 09:00 that following day.
      // if just do what is below then will not be in a valid range due to it not being 16:00 yet "today", thus the datesSpanDayRetryAttempt is true
      if (datesSpanDayRetryAttempt) {
        startRange = new Date(startRange.setTime( startRange.getTime() - 86400000)) //86400000 ms in a day
        endRange = new Date(endRange.setTime( endRange.getTime() - 86400000)) //86400000 ms in a day
      } else if (utcTimeTomorrowButReallyTodayAfterUtcDayRoll) {
        startRange = new Date(startRange.setTime( startRange.getTime() + 86400000)) //86400000 ms in a day
        endRange = new Date(endRange.setTime( endRange.getTime() + 86400000)) //86400000 ms in a day
      }
  }

  return {startRange, endRange}
}

const convertToTodaysDate = function (date, timezone) {
	// Logger.info(`converting date to today: ${JSON.stringify(date)}`)
  let newDate = new Date()
  if(moment.isMoment(date)) {
    // do something
    newDate = moment().tz(timezone)
    newDate.hours(date.hours())
    newDate.minutes(date.minutes())
    newDate.seconds(date.seconds())
    
    return newDate
  }

  console.log("convertToTodaysDate", date, typeof date, moment.isMoment(date))
	
	newDate.setHours(date.getHours())
	newDate.setMinutes(date.getMinutes())
	newDate.setSeconds(date.getSeconds())
  
	return newDate
}
